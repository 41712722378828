import React, {useEffect, useState} from 'react';
import styles from './index.module.css';


interface FullyHoverableProps {
    text: string;
    children: React.ReactNode;
}

function getTooltipDisplayTime(text: string) {
    // Constants
    const timePerCharacterMs = 48; // average reading time per character in milliseconds
    const minDisplayTimeMs = 2000; // minimum display time in milliseconds

    // Calculate display time based on text length
    let displayTime = text.length * timePerCharacterMs;

    // Ensure display time is within the min and max bounds
    displayTime = Math.max(displayTime, minDisplayTimeMs);

    return displayTime;
}

function FullyHoverable({ text, children}: FullyHoverableProps) {
    const [isHovered, setIsHovered] = useState(false);
    const timeout = React.useRef(undefined as NodeJS.Timeout | undefined);

    useEffect(() => {
        if (isHovered) {
            setTimeout(() => {
                setIsHovered(false);
            }, getTooltipDisplayTime(text));
        }
    }, [isHovered, text]);

    const handlePointerEnter = () => {
        if (timeout.current) {
            clearTimeout(timeout.current)
            timeout.current = undefined;
        }
        timeout.current = setTimeout(() => {
            setIsHovered(true);
        }, 500)
    };

    const handlePointerLeave = () => {
        if (timeout.current) {
            clearTimeout(timeout.current)
            timeout.current = undefined;
        }
        setIsHovered(false);
    };

    return (
        <div className={`${styles.container}`}>
            <div
                className={styles.children}
                onPointerEnter={handlePointerEnter}
                onPointerLeave={handlePointerLeave}
            >
                {children}
            </div>
            <div className={styles.hover + (!isHovered ? ' ' + styles.hidden : '')}>{text}</div>
        </div>
    );
}

export default FullyHoverable;
