import React from "react";
import styles from './styles.module.css'
import {Link, Outlet, useLocation} from "react-router-dom";
import FooterSpace from "../footerspacer";
import {Table, TableBody, TableCell, TableRow } from "@mui/material";
import {I18N, SimpleI18NMessage, useSimpleI18n} from "../../utils/i18n";
import CharacterSettings from "./characters";
import SubscriptionPage from "./subscription";
import SecuritySettingsPage from "./security";
import DeleteAccountScreen from "./deleteaccount";
import IntegrationsScreen from "./integrations";
import ExportDataPage from "./exportdata";

interface SettingPage {
    title: string;
    subPage: string;
    description: string;
    component: () => JSX.Element;
}

export const settingsPages: SettingPage[] = [
    {
        title: 'Characters',
        subPage: 'characters',
        description: 'Adjust how images are generated, by describing the people (characters) in your life',
        component: CharacterSettings
    },
    {
        title: 'Subscription',
        subPage: 'subscription',
        description: 'EquiQuill offers core features for free. As we expand, free features might change. Get a lifetime membership now to ensure access to all features permanently.',
        component: SubscriptionPage
    },
    {
        title: 'Security',
        subPage: 'security',
        description: 'Configure end-to-end encryption, along with other security settings',
        component: SecuritySettingsPage
    },
    {
        title: 'Integrations',
        subPage: 'integrations',
        description: 'Integrate EquiQuill with other Apps such as Strava',
        component: IntegrationsScreen
    },
    {
        title: 'Delete Account',
        subPage: 'deleteaccount',
        description: 'Permanently delete your account and all associated data',
        component: DeleteAccountScreen
    },
    {
        title: 'Export Data',
        subPage: 'exportdata',
        description: 'Download a copy of your journal entries and associated data',
        component: ExportDataPage
    }
]

function SettingsPage() {
    // if there is NO subroute, display all settings
    const location = useLocation();
    const isSubroute = !location.pathname.endsWith('/settings');
    const subRoute = location.pathname.split('/').pop();
    const i18n = useSimpleI18n()
    const subRouteCapitalized = subRoute ? subRoute.charAt(0).toUpperCase() + subRoute?.slice(1) : '';
    const subRouteI  = i18n(subRouteCapitalized);

    if (isSubroute) {
        return (
            <div className={styles.outlet}>
                <div className={styles.path}>
                    <Link to='.'><I18N>Settings</I18N></Link><span> &gt; {subRouteI}</span>
                </div>
                <Outlet/>
                <FooterSpace/>
            </div>
        )
    }

    return (
        <div className={styles.drawingArea}>
            <div className={styles.settingHeaders}>
                <h2><SimpleI18NMessage msg='Settings'/></h2><p>
                <SimpleI18NMessage msg='Customize your EquiQuill experience, by adjusting the item categories below'/></p>
                <Table>
                    <TableBody>
                    {settingsPages.map((page) => {
                        return (
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Link to={page.subPage}><SimpleI18NMessage msg={page.title}/></Link>
                                    </TableCell>
                                    <TableCell>
                                        <I18N>{page.description}</I18N>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        )
                    })}
                    </TableBody>
                </Table>
            </div>
            <div className={styles.settingsOutlet}>
                <Outlet/>
            </div>
        </div>
    );
}

export default SettingsPage;
