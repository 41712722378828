import React, {useContext, useEffect, useState} from "react";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import {ReflectDialog} from "../reflectors/reflectdialog";
import {useLocation} from "react-router-dom";
import {AES, enc} from "crypto-js";
import styles from "./styles.module.css";
import {ReflectContent} from "../reflectors/reflectcontent";
import {Button, Card, CardContent} from "@mui/material";

const SharedEntry = () => {
    const [displayText, setDisplayText] = useState('')
    const [displayImage, setDisplayImage] = useState('')
    const [isReady, setIsReady] = useState(false)

    const esApi = useContext(EsApiContext);
    const location = useLocation()
    const idParam = location.pathname.split('/')[2]

    // id has two parts, the first 20 characters are the scribEntryId, and the rest is the password
    const pwd = idParam.substring(20);
    const id = idParam.substring(0, 20);

    useEffect(() => {
        (async ()=>{
            const entry = await esApi.getSharedEntry(id)
            const dt = AES.decrypt(entry.encryptedReflection, pwd).toString(enc.Utf8);
            const im = AES.decrypt(entry.encryptedImageUrl, pwd).toString(enc.Utf8);
            setDisplayText(dt)
            setDisplayImage(im)
            setIsReady(true)
        })()
    }, []);


    function handleClose() {
        setDisplayText('')
        setDisplayImage('')
        window.location.href = 'https://app.equiquill.com/#/login'
    }

    function signup() {
        window.location.href = 'https://app.equiquill.com/#/login?signUp=true'
    }

    return (
        <div className={styles.dialogMain}>
            {/*<div className={styles.dialogPreamble}>{preAmble}</div>*/}
            <Card className={styles.dialogPreamble}>
                <CardContent>
                    <h3>How do I feel today?</h3>
                    {displayText && displayImage && <div>
                        A picture is worth a thousand words. So here's a picture that captures my mood,
                        and what an AI psychologist had to say about it.</div>}
                    {!displayText && displayImage && <div>
                        A picture is worth a thousand words. So here's a picture that captures my mood,
                    </div>}
                    {displayText && !displayImage && <div>
                        I'm having quite the day! I've shared my thoughts and feelings a with an AI psychologist, and
                        here's what they had to say.</div>}
                    <div>
                        <br/>
                        How about you!? <Button onClick={signup} variant={'outlined'}>Sign-up</Button> today to start
                        your own journal.
                    </div>
                </CardContent>

            </Card>
            <div className={styles.reflectContent}>
                {isReady && <ReflectContent
                    scribEntryId={''}
                    displayText={displayText ? `AI Psychologist: "${displayText}"` : ''}
                displayImage={displayImage}/>
            }
            </div>
        </div>
    );
}

export default SharedEntry;