/* eslint-disable */
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress} from "@mui/material";
import {I18N, useSimpleI18n} from "../../../utils/i18n";
import Button from "@mui/material/Button";
import React, {useContext} from "react";
import styles from './styles.module.css'
import {calculateEncryptedStringHeading, EncryptionParameters} from "../../../shared/utils/crypto";
import {EsApiContext} from "../../../utils/equi-scrib-internal-api-context";
import {UserSettings} from "../../../shared/types";

export enum ConversionState {
    STARTING = 'STARING',
    CALCULATING_SIZE = 'CALCULATING_SIZE',
    READY_FOR_NEXT = 'READY_FOR_NEXT',
    PROCESSING = 'PROCESSING',
    FINALIZING = 'FINALIZING',
    CANCELLED = 'CANCELLED',
    ERRORED = 'ERRORED',
    DONE = 'DONE',
}

export const FINAL_STATES = [ConversionState.DONE, ConversionState.ERRORED, ConversionState.CANCELLED];


export type ConversionSettings = {
    pseudoE2eKeyFrom: string;
    pseudoE2eKeyVersionFrom: number;
    trueE2eKeyFrom: string;
    trueE2eKeyVersionFrom: number;

    pseudoE2eKeyTo: string;
    pseudoE2eKeyVersionTo: number;
    trueE2eKeyTo: string;
    trueE2eKeyVersionTo: number;
}

export type DataConversionDialogProps = {
    conversionSettings: ConversionSettings
    onFinish: (completedSuccessfully: boolean) => void;
}

export function getConversionStateText(state: ConversionState): string {
    switch (state) {
        case ConversionState.STARTING:
            return "Starting key conversion.";
        case ConversionState.CALCULATING_SIZE:
            return "Loading list of entries to recrypt.";
        case ConversionState.READY_FOR_NEXT:
        case ConversionState.PROCESSING:
            return "Processing entry {0} of {1}. {2}% complete, {3}m{4}s remaining.";
        case ConversionState.FINALIZING:
            return "Conversion complete. Saving settings.";
        case ConversionState.CANCELLED:
            return "Key conversion has been canceled. {0} entries converted out of {1}. Converted entries will not be accessible until the conversion is complete.";
        case ConversionState.ERRORED:
            return "Key conversion failed: {5}.";
        case ConversionState.DONE:
            return "Key conversion complete. {1} entries converted in {3}m{4}s";
    }
}

const DataConversionDialog = (props: DataConversionDialogProps) => {

    const esApi = useContext(EsApiContext);

    const [currentState, setCurrentState] = React.useState<ConversionState>(ConversionState.STARTING);
    const [totalItems, setTotalItems] = React.useState<number>(0);
    const [nowAt, setNowAt] = React.useState<number>(-1);
    const [startTime, setStartTime] = React.useState<number>(0);
    const [error, setError] = React.useState<string>("");
    const [isCancelled1, setIsCancelled1] = React.useState<boolean>(false);
    const isCancellingRef = React.useRef(false);

    // display a MUI dialog
    const i18n = useSimpleI18n();
    const stateText = i18n(getConversionStateText(currentState));
    const isDivideByZero = nowAt - totalItems === 0 || totalItems === 0;
    // 4 minutes for 100
    // x minutes for 1000
    const elapsedSeconds = (Date.now() - startTime) / 1000;
    const timeLeftSeconds = (elapsedSeconds / nowAt) * (totalItems - nowAt);
    const percentComplete = Math.round(isDivideByZero ? 100 : (nowAt / totalItems) * 100);

    // check if timeLeftSeconds is infinity
    let minLeft: string;
    let secLeft: string;
    if (FINAL_STATES.includes(currentState)) {
        // if we're done, just show the time it took
        minLeft = Math.floor(elapsedSeconds / 60).toString();
        secLeft = Math.floor(elapsedSeconds % 60).toString();
    } else if (timeLeftSeconds === Infinity) {
        minLeft = "?";
        secLeft = "?";
    } else {
        minLeft = Math.floor(timeLeftSeconds / 60).toString();
        secLeft = Math.floor(timeLeftSeconds % 60).toString();
    }

    const msg = stateText.replace("{0}", nowAt.toString())
        .replace("{1}", totalItems.toString())
        .replace("{2}", percentComplete.toString())
        .replace("{3}", minLeft)
        .replace("{4}", secLeft)
        .replace("{5}", error || "");

    const variant = nowAt < 0 ? "indeterminate" : "determinate";

    const isFinished = FINAL_STATES.includes(currentState);

    const closeLabel = i18n(isFinished ? "Close" : "Cancel");
    const isRunningRef = React.useRef(false);

    async function doProcessingLoop() {
        if (isRunningRef.current) {
            return;
        }
        isRunningRef.current = true;
        try {
            const allPromises = [];
            const newKey = {
                pseudoE2eKey: props.conversionSettings.pseudoE2eKeyTo,
                pseudoE2eKeyVersion: props.conversionSettings.pseudoE2eKeyVersionTo,
                trueE2eKey: props.conversionSettings.trueE2eKeyTo,
                trueE2eKeyVersion: props.conversionSettings.trueE2eKeyVersionTo
            } as EncryptionParameters;
            console.log(`Initializing conversion.  Calculating size`);
            setCurrentState(ConversionState.CALCULATING_SIZE);
            let nowAtLoop = -1;
            setNowAt(nowAtLoop)
            const keyType = calculateEncryptedStringHeading(newKey.pseudoE2eKeyVersion, newKey.trueE2eKeyVersion)
            const toConvert = await esApi.getScribAndReflectionKeys(keyType);
            setTotalItems(toConvert.length);
            setNowAt(nowAtLoop = 0);
            setCurrentState(ConversionState.READY_FOR_NEXT);
            setStartTime(Date.now())
            while (true) {
                if (isCancellingRef.current) {
                    setIsCancelled1(true);
                    break;
                }
                setCurrentState(ConversionState.PROCESSING);
                const item = toConvert[nowAtLoop];
                if (!item) {
                    await Promise.all(allPromises);
                    // we're done
                    setCurrentState(ConversionState.FINALIZING);
                    break;
                }
                let oneUpdate: Promise<any>
                if (item.length < 12) {
                    // it is a scrib entry
                    oneUpdate = esApi.getScribEntries(item).then(
                        (entries) => esApi.updateScribEntryNew(entries[0], newKey)
                    );
                } else {
                    oneUpdate = esApi.getReflection(item).then(
                        (reflectionEntry) => esApi.updateReflection(reflectionEntry, newKey)
                    );
                }
                nowAtLoop++;
                // batch 10 at a time
                allPromises.push(oneUpdate);
                if (allPromises.length >= 10) {
                    await Promise.all(allPromises);
                    allPromises.length = 0;
                }
                // if we just processed the last item, goto to finalizing
                setCurrentState(ConversionState.READY_FOR_NEXT);
                setNowAt(nowAtLoop);
            }
            // we're done
            if (!isCancellingRef.current && !isCancelled1) {
                const finalSettings = {
                    ...await esApi.getSettings(),
                    pseudoE2eKey: props.conversionSettings.pseudoE2eKeyTo,
                    pseudoE2eKeyVersion: props.conversionSettings.pseudoE2eKeyVersionTo,
                    trueE2eKey: props.conversionSettings.trueE2eKeyTo,
                    trueE2eKeyVersion: props.conversionSettings.trueE2eKeyVersionTo,
                    newTrueE2eKeyVersion: undefined,
                    newPseudoE2eKey: undefined,
                    newPseudoE2eKeyVersion: undefined,
                } as UserSettings
                await esApi.updateSettings(finalSettings);
                setCurrentState(ConversionState.DONE);
            } else {
                setCurrentState(ConversionState.CANCELLED);
            }
        } catch (e: any) {
            setError(e.toString());
            setCurrentState(ConversionState.ERRORED);
        }
    }

    React.useEffect(() => {
        doProcessingLoop().then(_ => {})
    }, [])


    async function onCancel() {
        if (!isFinished) {
            isCancellingRef.current = true
        } else {
            // save the new settings
            props.onFinish(true);
        }
    }

    return (
        <Dialog className={styles.conversionDialog} open={true} fullWidth={true}>
            <DialogTitle><I18N>Converting Data</I18N></DialogTitle>
            <DialogContent>
                <DialogContentText
                    className={currentState === ConversionState.ERRORED ? styles.error : styles.ok}>{msg}</DialogContentText>
                {
                    !isFinished &&
                    <LinearProgress variant={variant} value={isDivideByZero ? 100 : (nowAt / totalItems) * 100}/>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()} autoFocus>{closeLabel}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DataConversionDialog;