import 'cordova-plugin-purchase'
import {Capacitor} from "@capacitor/core";
import {singleton} from "../../../shared/utils/equi-scrib-internal-api";
import {Plan} from "../../../shared/types";
import TransactionState = CdvPurchase.TransactionState;
import ProductType = CdvPurchase.ProductType;


const APP_STORE_PRODUCT_NAME = 'eqq_lifetime_202301'

export enum LifetimeSubscriptionState {
    NOT_PURCHASED,
    PENDING,
    PURCHASED,
}

export enum BuyLifetimeSubscriptionResult {
    SUCCESS,
    PURCHASE_PENDING = 1,
    ALREADY_OWNED,
    ERROR,
}

class CdvPurchaseService {
    private store: CdvPurchase.Store;
    private readonly initializePromise: Promise<void>;
    private stateChangedListeners: (() => void)[] = []
    private lifeTime: CdvPurchase.Product|undefined;
    private isBuying: boolean = false;
    private isLifetime: boolean = false;

    constructor() {
        this.store = CdvPurchase.store
        if (!Capacitor.isNativePlatform()) {
            console.log("Not native platform.  Short-circuiting CdvPurchase to noop.")
            this.initializePromise = Promise.resolve()
            return;
        }
        console.log("Making register call");
        this.store.register([{
            id: APP_STORE_PRODUCT_NAME,
            type: CdvPurchase.ProductType.CONSUMABLE,
            platform: CdvPurchase.Platform.GOOGLE_PLAY
        }, {
            id: APP_STORE_PRODUCT_NAME,
            type: ProductType.NON_CONSUMABLE,
            platform: CdvPurchase.Platform.APPLE_APPSTORE
        }] as CdvPurchase.IRegisterProduct[])
        const createGenericCallback = (name: string) => {
            return (param: any) => {
                console.log(name, param)
                this.stateChanged()
            }
        }
        console.log("Registering call backs");
        this.store.when()
            .productUpdated(createGenericCallback('productUpdated'))
            .pending(createGenericCallback('pending'))
            .receiptsReady(createGenericCallback('receiptsReady'))
            .initiated(createGenericCallback('initiated'))
            .approved((t) => this.acknowledge(t))
            .finished(createGenericCallback('finished'))
            .receiptsVerified(createGenericCallback('receiptsVerified'))
            .receiptUpdated(createGenericCallback('receiptUpdated'))
            .unverified(createGenericCallback('unverified'))
            .verified(createGenericCallback('verified'))
        console.log("About to initialize store");
        this.initializePromise = this.store.initialize([
            CdvPurchase.Platform.GOOGLE_PLAY, CdvPurchase.Platform.APPLE_APPSTORE
        ])
            .then((_result) => {
                console.log("Trying to retrieve lifetime product");
                this.lifeTime = this.store.get(APP_STORE_PRODUCT_NAME)!
                if (!this.lifeTime) {
                    throw new Error("Failed to get life time subscription for " + APP_STORE_PRODUCT_NAME)
                } else {
                    console.log("lifetime is: ", this.lifeTime)
                }
            })
            .then( async () => {
                console.log("Checking server for lifetime status")
                const esApi = singleton()
                const settings = await esApi.getSettings(true)
                if (settings.plan === Plan.LIFETIME) {
                    console.log("Plan already marked as lifetime plan")
                    this.isLifetime = true
                    this.stateChanged()
                }
            })
    }

    public async awaitReady() {
        await this.initializePromise
    }

    private stateChanged() {
        this.stateChangedListeners.forEach(l => l())
    }
    public addStateChangedListener(listener: () => void) {
        this.stateChangedListeners.push(listener)
    }
    public removeStateChangedListener(listener: () => void) {
        this.stateChangedListeners = this.stateChangedListeners.filter(l => l !== listener)
    }

    private get privateLifeTimeSubscriptionState(): LifetimeSubscriptionState {
        const localReceipt = this.store.findInLocalReceipts(this.lifeTime!)
        if (!localReceipt) {
            return LifetimeSubscriptionState.NOT_PURCHASED
        }
        switch (localReceipt.state) {
            case TransactionState.UNKNOWN_STATE:
            case CdvPurchase.TransactionState.CANCELLED:
                return LifetimeSubscriptionState.NOT_PURCHASED
            case TransactionState.APPROVED:
            case TransactionState.FINISHED:
                return LifetimeSubscriptionState.PURCHASED
            case TransactionState.PENDING:
            case TransactionState.INITIATED:
                return LifetimeSubscriptionState.PENDING
        }
    }
    get lifeTimeSubscriptionState(): LifetimeSubscriptionState|undefined {
        console.log(`Asking for lifetime subscription state: ${this.isLifetime}`)
        if (this.isLifetime) {
            return LifetimeSubscriptionState.PURCHASED
        }
        if (!this.lifeTime) {
            return undefined
        }
        if (this.isBuying) {
            return LifetimeSubscriptionState.PENDING
        }
        return this.privateLifeTimeSubscriptionState

    }

    async buyLifetimeSubscription() {
        this.isBuying = true;
        try {
            this.stateChanged()
            await this.awaitReady()
            const privatePurchaseState = this.privateLifeTimeSubscriptionState
            if (privatePurchaseState === LifetimeSubscriptionState.PURCHASED) {
                return LifetimeSubscriptionState.PURCHASED
            }
            if (privatePurchaseState === LifetimeSubscriptionState.PENDING) {
                return BuyLifetimeSubscriptionResult.PURCHASE_PENDING
            }
            const orderPromise = this.lifeTime?.getOffer()?.order()
            try {
                this.stateChanged()
                const result = await orderPromise
                if (!result) {
                    return BuyLifetimeSubscriptionResult.PURCHASE_PENDING;
                }
                if (result.isError) {
                    return BuyLifetimeSubscriptionResult.ERROR
                }
                return BuyLifetimeSubscriptionResult.SUCCESS
            } catch (e) {
                console.error(e)
                return BuyLifetimeSubscriptionResult.ERROR
            }
        } finally {
            this.isBuying = false;
            this.stateChanged()
        }
    }

    private getConfirmedLifetimePurchase() {
        const localReceipts = this.store.localReceipts
        for (const receipt of localReceipts) {
            for (const tran of receipt.transactions) {
                if (tran.state === TransactionState.APPROVED)
                    for (const product of tran.products) {
                        if (product.id === APP_STORE_PRODUCT_NAME) {
                            if (tran.platform === 'ios-appstore') {
                                const nativeOsReceipt = (receipt as any)
                                return tran.transactionId + '.' + nativeOsReceipt.nativeData?.appStoreReceipt
                            }
                            return (tran as any).nativePurchase.purchaseToken
                        }
                    }
            }
        }
        return undefined
    }

    private async acknowledge(t: CdvPurchase.Transaction) {
        this.stateChanged()
        console.log("Acknowledging receipt")
        // get settings & save the purchase token
        // TODO P2: VERIFY SERVER SIDE
        const esApi = singleton()
        const settings = await esApi.getSettings(true)
        const purchaseToken = this.getConfirmedLifetimePurchase();
        if (!purchaseToken) {
            console.warn("Approved transaction without purchase token")
            return;
        }
        const allTokens = settings.planPurchaseToken || []
        if (allTokens.includes(purchaseToken)) {
            console.warn("Purchase token already in list")
            return;
        }
        allTokens.push(purchaseToken)
        // push a new token
        await esApi.updateSettings({...settings,
            planPurchaseToken: allTokens,
            plan: Plan.LIFETIME
        })
        console.log("Purchase confirmed")
        await t.finish()
        console.log("Purchase finished")
        this.stateChanged()
    }

    async refresh() {
        return this.store.update()
    }
}

export default CdvPurchaseService;