import React, {FunctionComponent, ReactNode, useContext, useState} from 'react';
import {Snackbar} from "@mui/material";

// Define the type for the context value
export type SnackbarContextType = {
    displayMessage: (msg: string) => void;
    message: string;
    open: boolean
};

export const SnackbarContext = React.createContext<SnackbarContextType>(undefined as any);

// Props type for the provider component
interface SnackbarProviderProps {
    children: ReactNode;
}

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider: FunctionComponent<SnackbarProviderProps> = ({ children }) => {
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);

    const displayMessage = (msg: string) => {
        setMessage(msg);
        setOpen(true);
    };

    return (
        <SnackbarContext.Provider value={{message, open, displayMessage}}>
            {children}
            {/* Snackbar component from Material-UI */}
            <Snackbar
                open={open}
                message={message}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
            />
        </SnackbarContext.Provider>
    );
};
