import React, { useState, useCallback } from 'react';
import {Grid, Paper, Fab, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import styles from './styles.module.css';
import { Character } from '../../../../shared/types';
import { PersonaSelectionMatrix } from '../../../personaselection';
import CharacterCard from '../charactercard';
import {useSimpleI18n} from "../../../../utils/i18n";

export type CharacterFormProperties = {
    readonly characters: Character[],
    readonly onCharactersChanged: (newCharacters: Character[]) => void,
}

type DragItem = {
    type: string;
    id: number;
    index: number;
};

const ItemTypes = {
    CARD: 'card'
};

const CharacterForm: React.FC<CharacterFormProperties> = (props: CharacterFormProperties) => {
    const i18n = useSimpleI18n();

    const [editingCharacter, setEditingCharacter] = useState<{ index: number, character: Character } | undefined>(undefined);

    const handleAdd = () => {
        setEditingCharacter({ index: props.characters.length, character: { name: '', description: ''} });
    };

    const handleEdit = (index: number, character: Character) => {
        setEditingCharacter({ index, character });
    };

    const handleDelete = (index: number) => {
        const newCharacters = [...props.characters];
        newCharacters.splice(index, 1);
        props.onCharactersChanged(newCharacters);
    };

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        const newCharacters = [...props.characters];
        const draggedChar = newCharacters[dragIndex];
        newCharacters.splice(dragIndex, 1);
        newCharacters.splice(hoverIndex, 0, draggedChar);
        props.onCharactersChanged(newCharacters);
    }, [props.characters, props.onCharactersChanged]);

    const handlePersonaSelectionComplete = () => {
        if (!editingCharacter) {
            return;
        }
        // Editing existing character
        const newCharacters = [...props.characters];
        newCharacters[editingCharacter.index] = editingCharacter.character;
        props.onCharactersChanged(newCharacters);
        setEditingCharacter(undefined);
    };

    function handleCancel() {
        setEditingCharacter(undefined);
    }

    function handleClose() {
        handlePersonaSelectionComplete()
    }

    function updateEditingCharacter(newChar: Character) {
        setEditingCharacter({
            index: editingCharacter!.index,
            character: newChar
        })
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <Grid container spacing={2} className={styles.grid} sx={{ position: 'relative' }}>
                {props.characters.map((character, index) => (
                    <Grid item key={index}>
                        <CharacterCard
                            key={index}
                            index={index}
                            character={character}
                            onEdit={(char) => handleEdit(index, char)}
                            onDelete={() => handleDelete(index)}
                            moveCard={moveCard}
                        />
                    </Grid>
                ))}
                <Grid item>
                    <Box
                        className={styles.addCard}
                        onClick={handleAdd}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Fab color="primary" aria-label="add">
                            <AddIcon />
                        </Fab>
                    </Box>
                </Grid>
            </Grid>

            {(editingCharacter?.character) && (
                <Dialog open={true} onClose={handleCancel}>
                    <DialogTitle>{i18n('Edit Character')}</DialogTitle>
                    <DialogContent className={styles.dialogContent} sx={{
                        flex: 0,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: '24px',
                        maxHeight: 'calc(70dvh - 110px)'
                    }}>
                        <div style={{paddingTop:'8px'}}>
                            <PersonaSelectionMatrix
                                isPrompt0={false}
                                character={editingCharacter.character}
                                onChange={updateEditingCharacter}
                            />
                        </div>
                    </DialogContent>
                    <br/>
                    <DialogActions>
                        <Button onClick={handleCancel} color="primary">
                            {i18n('Cancel')}
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            {i18n('Done')}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </DndProvider>
    );
};

export default CharacterForm;
