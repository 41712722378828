import {EntrySummary, MAX_ENTRIES_PER_DAY, seSmallImage} from "../../shared/types";
import styles from './styles.module.css';
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {Icon} from "@mui/material";
import {FormatDateOptions, useIntl} from "react-intl";
import {I18N, SimpleI18NMessage} from "../../utils/i18n";
import {TUT_2_CLASSNAME} from "../tutorial";
import {useState} from "react";

export interface DayEntryProps {
    date: string;
    loadingEntry: boolean;
    totalEntriesLoaded: number;
    error: string
    entries: EntrySummary[];
}

export const DayEntry = (props: DayEntryProps) => {
    // date is as yyyymmdd, display it as Monday, Nov 3.
    const navigate = useNavigate();
    const intl = useIntl();

    const options : FormatDateOptions = {
        weekday: 'long',
        month: 'short',
        day: 'numeric'
    }
    const dateStr = intl.formatDate(new Date(
        parseInt(props.date.slice(0, 4)),
        parseInt(props.date.slice(4, 6)) - 1,
        parseInt(props.date.slice(6, 8))
    ), options);


    function handleClick(date: string, id: number) {
        navigate(`/dayview/${date}.${id}`)
    }

    // if date is today, and there are no entries today (or on any other day)
    // show welcome message
    const todayAsYYYYMMDD = new Date().toLocaleDateString('en-CA').replace(/-/g, '');
    const yesterdayAsYYYYMMDD = new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toLocaleDateString('en-CA').replace(/-/g, '');
    const showWelcome = !props.loadingEntry && !props.totalEntriesLoaded  && !props.error &&
        props.date === todayAsYYYYMMDD;

    return (
        <div className={styles.dayItem}>
            <div>{dateStr}</div>
            {props.loadingEntry && <div><SimpleI18NMessage msg={"Loading..."}/></div>}
            {props.error && <div>{props.error}</div>}
            {!props.loadingEntry && !props.error && (
            <div style={{display: "flex", flexDirection: 'row'}}>
                {props.entries.length === 0 && (
                    <div className={styles.imageHolder + ' ' + styles.empty + (showWelcome ? ' ' + styles.showWelcome : '')}>
                        {showWelcome && <>
                            <p><I18N>Welcome to EquiQuill! Your personal space to reflect, explore, and grow every day.</I18N></p>
                            <p><I18N>It looks like you don't have any journal entries yet for today. Click the <span className={styles.showWelcome}>+</span> button to add an entry.</I18N></p>
                            </>
                        }
                        {!showWelcome && <I18N>No journal entries for this day.</I18N>}
                    </div>
                )}
                <div className={styles.scrollable}>
                {props.entries.map((e, id) => {
                    return (
                        <div key={id} className={styles.imageHolder} onClick={() => {
                            handleClick(props.date, id)
                        }}>
                            {
                                e.imageUrl && (<>
                                    <img src={seSmallImage(e.imageUrl)}/>
                                    <div className={styles.summaryEmoji}>{e.summaryEmoji?.split(',')[1]}</div>
                                </>)
                            }
                            {
                                !e.imageUrl && (<>
                                    <div className={styles.partialJE}>
                                        {e.summaryEmoji ? "Incomplete journal reflection" : "Partial journal entry"}
                                        <br/> <br/>
                                        click to continue...
                                    </div>
                                    <div className={styles.summaryEmoji}>{e.summaryEmoji?.split(',')[1]||'?'}</div>
                                </>)
                            }
                        </div>
                    )
                })}
                </div>
                {props.entries.length < MAX_ENTRIES_PER_DAY && (props.date === todayAsYYYYMMDD || props.date === yesterdayAsYYYYMMDD) && (
                    <div className={styles.imageHolder + ' ' + styles.addEntry
                        + (props.date === todayAsYYYYMMDD && props.entries.length === 0 ? ' ' + styles.bounce : '')
                    } onClick={() => handleClick(props.date, props.entries.length)}>
                        <Button variant="contained" color="primary" data-testid={'add-button'}>
                            <IconButton className={TUT_2_CLASSNAME}>
                                <Icon>+</Icon>
                            </IconButton>
                        </Button>
                    </div>
                )}
            </div>)}
        </div>
    )
}