import {useContext, useEffect, useState} from "react";
import styles from './styles.module.css'
import {useNavigate, useParams} from "react-router-dom";
import {MAX_ENTRIES_PER_DAY, ScribEntry} from "../../shared/types";
import {EntrySummaryButtons} from "../entrysummarybuttons";
import DayViewDetail from "../dayviewdetail/dayview";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import {useIntl} from "react-intl";

function parseQueryParams(queryDate: string): [Date, number] {
    const rePattern = `(20\\d{2})([01]\\d)([0123]\\d)(\\.[0-${MAX_ENTRIES_PER_DAY-1}])?`;
    const re = new RegExp(rePattern)
    const matches = queryDate?.match(re);
    if (!matches) {
        throw new Error("Unexpected URL: " + queryDate);
    }
    const dateObj = new Date(parseInt(matches[1]), parseInt(matches[2]) - 1, parseInt(matches[3]));
    const seq = matches[4] ? parseInt(matches[4].substring(1)) : 0;
    return [dateObj, seq];
}

const DayViewPage = () => {
    const dateStr = useParams().date_and_seq || '';
    const [entries, setEntries] = useState([] as ScribEntry[])
    const [dateObj, seq] = dateStr ? parseQueryParams(dateStr) : [undefined, undefined];
    const navigate = useNavigate();

    const esApi = useContext(EsApiContext)

    useEffect(() => {
        esApi.getScribEntries(dateStr.substring(0, 8)).then( (entries) => {
            setEntries(entries)
        })
    }, [dateStr, seq]);

    const intl = useIntl();

    if (!dateStr) {
        return <div>Invalid date</div>
    }

    function setOrAddEntry(n: number) {
        navigate(`/dayview/${dateStr.substring(0, 8)}.${n}`, {replace: true})
    }

    function refreshItems() {
        esApi.getScribEntries(dateStr.substring(0, 8)).then( (entries) => {
            setEntries(entries)
        })
    }

    return (
        <div style={{width: '100%'}}>
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <div className={styles.header}>
                        <div>
                            <div className={styles.headingText}>{ intl.formatDate(dateObj, {dateStyle: 'full'})}</div></div>
                            {entries && (<EntrySummaryButtons entries={entries} currentEntry={seq!} onEntryChange={setOrAddEntry}/>)}
                    </div>
                </div>
            </div>
            <DayViewDetail entryId={dateStr} onUpdatedOCR={refreshItems}/>
        </div>
    )
};

export default DayViewPage;
