import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import styles from "./styles.module.css";
import Button from "@mui/material/Button";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ErrorIcon from "@mui/icons-material/Error";
import {I18N} from "../../../utils/i18n";

export type PromptProps = {
    open: boolean;
    onResume?: () => void;
    onIgnore?: () => void;
}

const PromptConversionResumeDialog = (props: PromptProps) => {
    return (
        <Dialog open={props.open}>
            <DialogTitle><I18N>Incomplete Conversion Detected!</I18N></DialogTitle>
            <DialogContent>
                <p><I18N>It looks like your end-to-end encryption settings have changed, and that your
                    browser has not yet finished converting journal entries that were encrypted with
                    the old settings.  Fortunately, you can resume the conversion process from where
                    it left off.  Would you like to do so now?</I18N></p>
                <div className={styles.actions + ' ' + styles.resume}>
                    {props.onIgnore && (<Button color="inherit" size="small" startIcon={<ErrorIcon/>}
                            onClick={props.onIgnore}>
                        <I18N>Ignore</I18N>
                    </Button>)}
                    {props.onResume && (<Button color="inherit" size="small" startIcon={<PlayCircleOutlineIcon/>}
                                                onClick={props.onResume}>
                        <I18N>Resume Conversion</I18N>
                    </Button>)}
                </div>
                <div>&nbsp;</div>
            </DialogContent>
        </Dialog>
    )
}

export default PromptConversionResumeDialog;