import React from 'react';
import {SimpleI18NMessage} from "../../utils/i18n";

interface SignInWithFacebookProps {
    onSignIn: () => void;
}

const SignInWithFacebook = (props: SignInWithFacebookProps) => {
    const signIn = async () => {
        props.onSignIn();
    }

    // @ts-ignore
    return (
        <button className="gsi-material-button" onClick={signIn}>
            <div className="gsi-material-button-state"></div>
            <div className="gsi-material-button-content-wrapper">
                <div className="gsi-material-button-icon">
                    <svg fill="#3b5998" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                        <path
                            d="M23 12.067C23 5.955 18.075 1 12 1S1 5.955 1 12.067C1 17.591 5.023 22.17 10.281 23v-7.734H7.488v-3.199h2.793V9.63c0-2.774 1.642-4.306 4.155-4.306 1.204 0 2.462.216 2.462.216v2.724h-1.387c-1.366 0-1.792.853-1.792 1.728v2.076h3.05l-.487 3.2h-2.563V23C18.977 22.17 23 17.591 23 12.067z"
                            fill=""></path>
                    </svg>
                </div>
                <span className="gsi-material-button-contents"><SimpleI18NMessage msg={'Sign in with Facebook'}/></span>
                <span style={{display: 'none'}}><SimpleI18NMessage msg={'Sign in with Facebook'}/></span>
            </div>
        </button>
    );
}

export default SignInWithFacebook;
