import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, LinearProgress, Paper, Typography} from '@mui/material';
import {EsApiContext} from '../../../utils/equi-scrib-internal-api-context';
import {I18N} from '../../../utils/i18n';
import styles from './styles.module.css';
import {DaySummary, Reflection, ScribEntry} from "../../../shared/types";
import {differenceInDays, differenceInMonths} from 'date-fns';


const formatDate = (date: Date): string =>
    date.getFullYear().toString() +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    date.getDate().toString().padStart(2, '0');

const formatDisplayDate = (dateStr: string): string => {
    const year = dateStr.slice(0, 4);
    const month = dateStr.slice(4, 6);
    const day = dateStr.slice(6, 8);
    return `${year}-${month}-${day}`;
};

const escapeCsvField = (field: string): string => {
    if (!field.includes(',') && !field.includes('"') && !field.includes('\n')) return field;
    return `"${field.replace(/"/g, '""')}"`;
};

const createCsvRow = (dateStr: string, index: number, entry: ScribEntry, reflection: Reflection | undefined): string => [
    `${dateStr}.${index}`,
    escapeCsvField(entry.overriddenText || entry.ocrText || ''),
    entry.emojiUtf8 || '',
    escapeCsvField(reflection?.text || ''),
    reflection?.image || ''
].join(',');

const downloadCsv = (csvRows: string[]) => {
    const blob = new Blob([csvRows.join('\n')], {type: 'text/csv;charset=utf-8;'});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'equiquill-data-export.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};

// won't work if multiple concurrent uses, but we only have one export queue.  So Ok.
let LAST_API_CALL = 0;

async function throttle<T>(action: string, param: () => Promise<T|undefined>) {
    try {
        const timeToPause = 100 - (Date.now() - LAST_API_CALL);

        // If the time elapsed is greater than or equal to 100ms, execute the function immediately
        if (timeToPause <= 0) {
            LAST_API_CALL = Date.now();
            return param();
        }

        // Otherwise, wait for the remaining time and then execute the function
        await new Promise(resolve => setTimeout(resolve, timeToPause));
        LAST_API_CALL = Date.now();
        return await param();
    } catch (e) {
        console.warn(`Failed in ${action}`, e);
        return undefined
    }
}

interface ExportEntry {
    date: string;
    emoji: string;
    imageUrl: string | undefined;
    idx: number;
}

const ExportDataPage = () => {
    const [isExporting, setIsExporting] = useState(false);
    const [progress, setProgress] = useState(0);
    const [recentExports, setRecentExports] = useState<ExportEntry[]>([])
    const nextToQueue = useRef<ExportEntry|undefined>(undefined)
    const containerRef = useRef<HTMLDivElement>(null);
    const [exportStatus, setExportStatus] = useState<string>()
    const updateTimerRef = useRef<NodeJS.Timeout>();

    // Handle visibility change
    useEffect(() => {
        const handleVisibilityChange = () => {
            clearAnimation();
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            if (updateTimerRef.current) {
                clearInterval(updateTimerRef.current);
            }
        };
    }, []);


    // Effect to handle the animation cycle
    const [exportedRows, setExportedRows] = useState(0);
    const [error, setError] = useState<string | undefined>(undefined);
    const esApi = useContext(EsApiContext);

    function clearAnimation() {
        setRecentExports([]);
    }

    const processEntries = async (dateStr: string): Promise<string[]> => {
        const rows: string[] = [];
        const entriesForDate = await throttle(`Fetching entries for ${dateStr}`,
            () => esApi.getScribEntries(dateStr)
        )
        if (!entriesForDate) {
            return rows;
        }
        for (const entry of entriesForDate) {
            if (!entry || !(entry.ocrText || entry.overriddenText)) {
                continue;
            }
            const reflection = entry.reflectionId ? await throttle(
                `Fetching reflection for ${entry.id}`,
                () => esApi.getReflection(entry.reflectionId!)
            ) : undefined
            rows.push(createCsvRow(dateStr, 0, entry, reflection));
        }
        return rows;
    }
    
    function fetchNext(fromDte: Date, toDte: Date) {
        return esApi.getSummaries(formatDate(fromDte), formatDate(toDte))
            .then(ss => ss.filter(s => s?.year && s.entries?.length > 0))
    }

    const exportData = async () => {
        setIsExporting(true);
        setError(undefined);
        setProgress(0);
        setExportedRows(0);

        setExportStatus('Fetching first set of rows to export...')

        const csvRows = ['Entry ID,Entry Text,Emoji,Psychological Assessment,Image URL'];
        const endDate = new Date();
        let nextSummariesPromise : Promise<DaySummary[]> = esApi.getSummaries(
            formatDate(new Date(2023, 9, 1)),
            formatDate(endDate)
        )
        const firstEntry = (await nextSummariesPromise)[0]
        const firstDate = new Date(firstEntry.year, firstEntry.month - 1, firstEntry.day);
        const totalDays = differenceInDays(endDate, firstDate)
        const months = differenceInMonths(endDate, firstDate)


        clearAnimation()

        let idx = 0;

        updateTimerRef.current = setInterval(() => {
            const newEntry = nextToQueue.current;
            if (!newEntry) {
                return;
            }
            setRecentExports(prev => [...prev, newEntry]);
            // launch a timer to remove the item from the list in 3 seconds
            setTimeout(() => {
                setRecentExports(prev => prev.filter(e => e.idx !== newEntry.idx));
            }, 10000);
        }, 1000);

        setExportStatus(`Exporting data.  This may take a few minutes. Enjoy this collage of the last ${months} months of journal entries unfold as you wait.`)

        while (nextSummariesPromise !== undefined) {
            const summaries = await nextSummariesPromise;
            if (summaries.length === 0) {
                break
            }
            const lastSummary = summaries[summaries.length - 1];
            const lastSummaryDate = new Date(lastSummary.year, lastSummary.month - 1, lastSummary.day);
            lastSummaryDate.setDate(lastSummaryDate.getDate() + 1);
            nextSummariesPromise = fetchNext(lastSummaryDate, endDate)

            for (const summary of summaries) {
                const entryDate = new Date(summary.year, summary.month - 1, summary.day);
                for (const entry of summary.entries) {
                    const emojiPart = (entry.summaryEmoji || '').split(',')[1] || '';
                    const newEntry: ExportEntry = {
                        date: entryDate.toISOString().substring(0, 10),
                        emoji: emojiPart.trim(),
                        imageUrl: entry.imageUrl || undefined,
                        idx: idx++,
                    }
                    nextToQueue.current = newEntry;
                    const dateStr = formatDate(entryDate);
                    const newRows = await processEntries(dateStr);
                    csvRows.push(...newRows);
                    setExportedRows(idx);
                    const daysDone = differenceInDays(entryDate, firstDate) + 1;
                    const progress = Math.round(daysDone / totalDays * 100);
                    setProgress(progress);
                }
            }
        }

        setExportStatus(`Export complete and downloaded to your downloaded items folder. ${idx} entries exported.`)
        if (updateTimerRef.current) {
            clearInterval(updateTimerRef.current);
        }
        downloadCsv(csvRows);
        setProgress(100);
    };

    return (
        <div className={styles.container}>
            <Typography variant="h5" component="h2" gutterBottom>
                <I18N>Export Your Data</I18N>
            </Typography>

            <Typography paragraph>
                <I18N>
                    Download a copy of your journal entries, including text, emojis, psychological assessments, and
                    image URLs.
                    The export will include data from the last 2 years in CSV format.
                </I18N>
            </Typography>

            {error && (
                <Typography color="error" paragraph>
                    {error}
                </Typography>
            )}

            <div className={styles.actionArea}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={exportData}
                    disabled={isExporting}
                >
                    <I18N>Export Data</I18N>
                </Button>
            </div>
            {isExporting && (

                <Paper elevation={1} className={styles.progressContainer}>
                    <div className={styles.progressInfo} ref={containerRef}>
                        <div className={styles.progressHeader}>
                            <Typography variant="body2" color="primary" sx={{ fontWeight: 'bold' }}>
                                {exportStatus}
                            </Typography>
                            <br/>
                            <LinearProgress
                                variant="determinate" 
                                value={progress} 
                                sx={{ height: 10, borderRadius: 5, mb: 2 }}
                            />
                            <Typography variant="body2" color="primary" sx={{ fontWeight: 'bold' }}>
                                {exportedRows} rows exported.
                            </Typography>
                        </div>
                        <div className={styles.recentExportsContainer}>
                            {recentExports.map((entry, idx) => (
                                <div key={entry.idx} className={styles.exportEntry}>
                                    <div className={styles.entryHeader}>
                                            <div className={styles.entryDate}>{entry.date}</div>
                                            <div className={styles.entryEmoji}>{entry.emoji}</div>
                                    </div>
                                    <img src={entry.imageUrl} className={styles.entryImage}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </Paper>
                )}
        </div>
    );
};

export default ExportDataPage;
