import React from 'react';
import {SimpleI18NMessage} from "../../utils/i18n";

interface SignInWithAppleProps {
    onSignIn: () => void;
}

const SignInWithApple = (props: SignInWithAppleProps) => {
    const signIn = async () => {
        props.onSignIn();
    }

    // @ts-ignore
    return (
        <button className="gsi-material-button" onClick={signIn}>
            <div className="gsi-material-button-state"></div>
            <div className="gsi-material-button-content-wrapper">
                <div className="gsi-material-button-icon">
                    <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                        <path
                            d="M19.957 8.819c-.121.09-2.26 1.244-2.26 3.81 0 2.968 2.722 4.019 2.803 4.045-.013.064-.432 1.438-1.435 2.838-.893 1.232-1.827 2.462-3.247 2.462s-1.785-.79-3.424-.79c-1.598 0-2.166.816-3.465.816s-2.205-1.14-3.247-2.54C4.475 17.816 3.5 15.26 3.5 12.837c0-3.888 2.64-5.95 5.237-5.95 1.38 0 2.531.868 3.398.868.825 0 2.11-.92 3.681-.92.595 0 2.734.051 4.141 1.984zm-4.886-3.63c.65-.739 1.109-1.763 1.109-2.787 0-.142-.013-.286-.04-.402-1.057.038-2.314.674-3.072 1.516-.595.648-1.15 1.672-1.15 2.71 0 .156.027.312.04.362.066.012.175.026.283.026.948 0 2.14-.608 2.83-1.426z"
                            fill=""></path>
                    </svg>
                </div>
                <span className="gsi-material-button-contents"><SimpleI18NMessage msg={'Sign in with Apple'}/></span>
                <span style={{display: 'none'}}><SimpleI18NMessage msg={'Sign in with Apple'}/></span>
            </div>
        </button>
    );
}

export default SignInWithApple
