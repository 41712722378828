import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import Papa from 'papaparse';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import {Character} from '../../shared/types';
import {useSimpleI18n} from '../../utils/i18n';
import styles from './styles.module.css';
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

interface PersonaSelectionMatrixProps {
    isPrompt0: boolean;
    character: Character;
    onChange: (updatedCharacter: Character) => void;
}

interface Persona {
    Real: boolean // it is realistic image vs. cartoon
    Description: string
    url: string
    Custom?: boolean // it is custom image/cartoon
    error?: string
}

const normalizeString = (str: string) => {
    return str?.toLowerCase()?.replace(/[^a-z0-9]/g, '');
};

function findPersonaIdx(character: Character, personas: Persona[]) {
    const sought = normalizeString(character.description);
    return personas.findIndex((persona) => normalizeString(persona.Description) === sought);
}

function findPersona(character: Character, personas: Persona[]) {
    const idx = findPersonaIdx(character, personas);
    return idx === -1 ? undefined : personas[idx];
}

export const PersonaSelectionMatrix: React.FC<PersonaSelectionMatrixProps> = ({
                                                                                  character,
                                                                                  isPrompt0,
                                                                                  onChange
                                                                              }) => {
    const i18n = useSimpleI18n();
    const containerRef = useRef<HTMLDivElement>(null);
    const esApi = useContext(EsApiContext);

    const [gridSize, setGridSize] = useState(5);
    const [cellCnt, setCellCnt] = useState(0);
    const [personas, setPersonas] = useState<Persona[]>([]);
    const [newInit, setNewInit] = useState(false);

    function setInitialPersonas(csv: any) {
        console.log("Fetching personas (" + personas.length + ")");
        const result = Papa.parse(csv, {header: true});
        const ps: Persona[] = result.data.map((row: any, idx: number) => ({
            Real: row.Real === 1,
            Description: row.Description,
            Custom: false,
            url: `persona_${idx < 9 ? "0" : ""}${idx + 1}.png`
        }));
        // shuffle all the personas
        for (let i = ps.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [ps[i], ps[j]] = [ps[j], ps[i]];
        }
        setPersonas(ps);
        setGridSize(updateGridSize(window.innerWidth));
        setCellCnt(Math.floor(ps.length / gridSize) * gridSize);
        setTimeout(() => setNewInit(true), 100);
    }

    useEffect(() => {
        fetch('/personas.csv')
            .then(response => response.text())
            .then(csv => setInitialPersonas(csv));
    }, []);

    useEffect(() => {
        if (newInit) {
            generatePreviewUrl()
            // also make sure that the selected character is first.
            const personaIdx = findPersonaIdx(character, personas);
            if (personaIdx > 0) {
                const newPersonas = [...personas];
                newPersonas.splice(personaIdx, 1);
                newPersonas.unshift(personas[personaIdx]);
                setPersonas(newPersonas);
            }
        }
    }, [newInit]);

    useEffect(() => {
        if (personas.length <= 1) {
            return;
        }
        const personaIdx = findPersonaIdx(character, personas);
        console.log("Updating persona to " + character.description + " (" + personaIdx + "/" + personas.length +")");
        if (personaIdx < 0) {
            // it is a custom one.  Remove all customs from personas.
            // create a new one, and put it at the start
            const newPersonas = personas.filter(p => !p.Custom);
            if (newPersonas.length === personas.length && !character.description) {
                return;
            }
            if (character.description) {
                newPersonas.unshift({Real: false, Description: character.description, Custom: true, url: ''});
            }
            setPersonas(newPersonas);
            return
        }
        if (personaIdx >= cellCnt) {
            // it is beyond, so we need to move it to the front.
            // do nothing
        } else if (personas[personaIdx]?.Custom) {
            // it is displayed in the cells displayed.  However,
            // it is a custom one, we'll need to re-add it unless descriptions match exactly.
            if (personas[personaIdx].Description === character.description) {
                return;
            }
            // descriptions don't match, so we'll need to re-add it.
        } else {
            // it is displayed in the cells displayed.  It is not custom, so we don't need to do anything
            return;
        }

        const toPutBack = personas[personaIdx];
        toPutBack.Description = character.description;
        const newPersonas = personas.filter((p, idx) => idx !== personaIdx);
        if (newPersonas[0]?.Custom) {
            // toPutBack as the 2nd (index 1) element
            newPersonas.splice(1, 0, toPutBack);
        } else {
            // toPutBack as the first element
            newPersonas.unshift(toPutBack);
        }
        setPersonas(newPersonas);
    }, [character.description, personas, gridSize]);

    async function generatePreviewUrl() {
        function updateUrl(generating: string, error?: string) {
            const newPersonas = [...personas];
            newPersonas[0] = {
                ...(newPersonas[0]),
                url: generating,
                error: error
            }
            setPersonas(newPersonas);
        }

        const persona = personas[0]
        console.log(`Generating preview for ${personas[0]?.Description} (Custom==${personas[0]?.Custom})`)
        if (!persona?.Custom) {
            return
        }

        updateUrl('generating');
        let newUrl;
        try {
            newUrl = await esApi.doPersonaPreview(persona.Description);
            updateUrl(newUrl)
        } catch (e: any) {
            updateUrl('error', e.response?.data || 'An error occurred. Please try refreshing.')
        }
    }

    const updateGridSize = (width: number) => {
        if (width < 400) return 3;
        if (width < 500) return 4;
        return 5;
    };

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                const newGridSize = updateGridSize(containerRef.current.clientWidth);
                setGridSize(newGridSize);
            }
        };

        const observer = new ResizeObserver(handleResize);
        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => observer.disconnect();
    }, []);


    const isSelected = useMemo(() => {
        const normalizedCustom = normalizeString(character.description);
        return (description: string) => normalizeString(description) === normalizedCustom;
    }, [character.description]);

    const renderGridCells = () => (
        personas.slice(0, Math.floor(personas.length / gridSize) * gridSize)
            .map((persona, cellIndex) => (
                    <div
                        key={cellIndex}
                        className={`${styles.personaCell} ${isSelected(persona.Description) ? styles.selected : ''}`}
                        onClick={() => handlePersonaClick(cellIndex)}
                    >
                        {
                            persona.url === 'generating' ?
                                <HourglassBottomIcon className={styles.hourGlass}/> :
                                persona.url === 'error' ?
                                    <span className={styles.previewError}>{persona.error}</span> :
                                    !persona.url ?
                                        <span className={styles.previewText}>{persona.Description}</span> :
                                        <img src={persona.url} alt={persona.Description}/>
                        }
                    </div>
                )
            )
    )

    const handlePersonaClick = (personaNumber: number) => {
        const persona = personas[personaNumber];
        const description = persona?.Description;
        onChange({...character, description});
    };

    return (
        <>
            {!isPrompt0 &&
                <TextField
                    label={i18n('Character names (comma-separated)')}
                    placeholder={i18n('e.g., John, Johnny, Jo')}
                    value={character.name}
                    onChange={(e) => onChange({...character, name: e.target.value})}
                    sx={{mb: 2}}
                    fullWidth
                />}
            <TextField
                label={i18n('Character description (realistic or imaginative)')}
                value={character.description}
                onChange={(e) => onChange({...character, description: e.target.value})}
                sx={{mb: 2}}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {personas[0]?.Custom &&
                                personas[0]?.Description === character.description &&
                                !personas[0]?.url && (
                                    <IconButton
                                        onClick={generatePreviewUrl}
                                        edge="end"
                                        size="small"
                                    >
                                        <PlayCircleOutlineIcon/>
                                    </IconButton>
                                )}
                        </InputAdornment>
                    ),
                }}
            />
            <div style={{flex: 1, overflow: 'auto', minHeight: 0}}>
                <div ref={containerRef} className={styles.matrixContainer + ' ' + styles['grid' + gridSize]}>
                    {renderGridCells()}
                </div>
            </div>
        </>
    )
};

export default PersonaSelectionMatrix;
