export default function isMobile(): boolean {
    // if not client side, then throw error
    if (typeof window === 'undefined') {
        return false
    }
    const cap = (window as any)?.Capacitor;
    if (cap) {
        const platform = cap.getPlatform();
        return platform === 'ios' || platform === 'android';
    } else {
        return false;
    }
}

export function isServer(): boolean {
    return typeof document === 'undefined';
}
