import React, {useContext, useState} from 'react';
import styles from './styles.module.css'
import {Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import { EsApiContext } from '../../../utils/equi-scrib-internal-api-context';
import LoadingOverlay from "../../loadingoverlay";
import {I18N, useSimpleI18n} from "../../../utils/i18n";

const MIN_LN=25

const DeleteAccountScreen = () => {
    const [oneThing, setOneThing] = useState('')
    const [confirmDialog, setConfirmDialog] = useState(false)
    const [deleting, setIsDeleting] = useState(false)
    const [hasBeenDeleted, setHasBeenDeleted] = useState(false)

    const esApi = useContext(EsApiContext)

    const i18n = useSimpleI18n()

    function updateOneThing(txt: any) {
        setOneThing(txt.target.value)
    }

    function deleteAccount() {
        setConfirmDialog(true)
    }

    async function finalizeDeletion() {
        setIsDeleting(true);
        await esApi.deleteAccount(oneThing)
        setIsDeleting(false);
        setHasBeenDeleted(true)
        console.log("Account Deleted")
    }

    function gotoLogoutScreen() {
        console.log("Going to logout screen");
        setHasBeenDeleted(false)
        setTimeout(() => {
            window.location.href = '/#/login'
            window.location.reload()
        }, 100)
    }

    return (
        <div className={styles.main}>
            {deleting && <LoadingOverlay/>}
            <div><I18N>We are so sorry to see you leave us. Thanks for trying us out.</I18N></div>
            <div><I18N>Before you go, what is one thing that would have made your EquiQuill Journey Better.</I18N></div>
            <TextField className={styles.oneThing}
                       multiline
                       label={i18n("EquiQuill would be better if...")}
                       rows={4}
                       fullWidth
                       value={oneThing}
                       onChange={updateOneThing}
                       helperText={`${oneThing.length}/${MIN_LN}`}
                       FormHelperTextProps={{
                           style: { textAlign: 'right', color: oneThing.length < MIN_LN ? 'red' : 'black' }
                       }}
            />
            <Button className={styles.deleteButton}
                    onClick={deleteAccount}
                    disabled={ oneThing.length < MIN_LN}
                    fullWidth
                    sx={{mt:2}}
                    color="error"><I18N>Delete Account</I18N></Button>

            <Dialog open={confirmDialog} onClose={() => setConfirmDialog(false)}>
                <DialogTitle><I18N>Irreversible Account Deletion</I18N></DialogTitle>
                <DialogContent>
                    <div><I18N>
                        Please note, this action cannot be undone.  Once you delete your account, you will lose
                        all of your journal entries
                    </I18N></div>
                    <div className={styles.confirmActions}>
                        <Button onClick={() => setConfirmDialog(false)}><I18N>CANCEL</I18N></Button>
                        <Button onClick={finalizeDeletion}><I18N>YES, DELETE!</I18N></Button>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog open={hasBeenDeleted} onClose={gotoLogoutScreen}>
                <DialogTitle><I18N>What was no longer is</I18N></DialogTitle>
                <DialogContent>
                    <div><I18N>
                        Your account and all of its associated data has been deleted.  We are sorry to see you go.
                    </I18N></div>
                    <div className={styles.confirmActions}>
                        <Button onClick={gotoLogoutScreen}><I18N>LOGOUT</I18N></Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DeleteAccountScreen;
