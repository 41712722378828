import styles from './styles.module.css'
import {State} from "../dayviewdetail/dayview";
import Reflector from "./reflector";
import {Line, MIN_LINES_LENGTH, MIN_TEXT_LENGTH, Reflection, ScribEntry} from "../../shared/types";
import React, {KeyboardEventHandler, useContext, useEffect, useState} from "react";
import {Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {EsApiContext} from "../../utils/equi-scrib-internal-api-context";
import {compressLines, newId, numberOfMinutesSinceDec12023} from "../../shared/utils";
import Button from "@mui/material/Button";
import {I18N, useSimpleI18n} from "../../utils/i18n";
import {useSnackbar} from "../globalsnackbar";
import {TUT_5_CLASSNAME} from "../tutorial";
import {ReflectDialog} from "./reflectdialog";
import {Refreshable} from '../../shared/utils/equi-scrib-internal-api';


export type ReflectorsProps = {
    lines: Line[]
    ocrText: string|Error|undefined,
    hasOcrd: boolean
    overrideText: string
    psychologistText?: string|Error
    emoji?: string|Error
    imagePrompt?: string|Error
    imageUrl?: string|Error
    showFinalImage?: boolean
    scribEntryId: string
    isPaused: boolean;

    state: State
    onRefresh(refreshable: Refreshable): any;
    onResume?: () => void;
}



function createSubmitOnEnter(handleSubmit: () => void): KeyboardEventHandler<HTMLDivElement> {
    return (e: any) => {
        if (e.key === 'Enter' && e.ctrlKey) {
            e.preventDefault();
            handleSubmit();
        }
    }
}

export const Reflectors = (props: ReflectorsProps) => {
    const isPaused = props.isPaused;
    const [displayText, setDisplayText] = useState<string | Error | undefined>(undefined);
    const [displayImage, setDisplayImage] = useState<string | Error | undefined>(undefined);
    const [refreshable, setRefreshable] = useState<Refreshable|undefined>(undefined);
    const [showInappropriate, setShowInappropriate] = useState(false);
    const [inappropriateComment, setInappropriateComment] = useState('');
    const navigate = useNavigate();
    const i18n = useSimpleI18n();

    const esApi = useContext(EsApiContext)
    const sb = useSnackbar()

    useEffect(() => {
        if (props.showFinalImage && props.imageUrl && props.psychologistText) {
            setDisplayImage(props.imageUrl);
            setDisplayText(props.psychologistText);
            setRefreshable(Refreshable.IMAGE_URL);
        }
    }, [props.showFinalImage, props.imageUrl, props.psychologistText]);

    function isSaved() {
        switch (props.state) {
            case State.AUTO_SAVING:
            case State.WAITING_FOR_SAVE_TIMEOUT:
                return false;
            default:
                return true;
        }
    }

    function isSaveErr() {
        return false;
    }

    function isSaving() {
        switch (props.state) {
            case State.AUTO_SAVING:
                return true;
            default:
                return false;
        }
    }

    function isSaveActionable() {
        return props.state !== State.WAITING_FOR_SAVE_TIMEOUT;
    }

    function isOCRd() {
        return !props.overrideText && (!!props.ocrText || props.hasOcrd);
    }

    function isOCRErr() {
        return !props.overrideText && isErr(props.ocrText);
    }

    function isOCRing() {
        return !props.overrideText && (props.state === State.OCR_ING);
    }

    function isOCRActionable() {
        if (!props.lines) {
            return false;
        }
        if ( props.lines.length <= MIN_LINES_LENGTH) {
            return false;
        }
        if (props.overrideText) {
            return false;
        }
        if (isSaveErr()) {
            return false;
        }
        return isSaveActionable() && isSaved();
    }

    function isEmojiD() {
        return !!props.emoji;
    }

    function isEmojiing() {
        return props.state === State.EMOJIING
    }

    function isEmojiErr(): boolean {
        return isErr(props.emoji)
    }

    function isEmojiActionable() {
        if (isErr(props.ocrText)) {
            return false;
        }
        return (props.overrideText || (props.ocrText as string))?.length >= MIN_TEXT_LENGTH && isSaveActionable() && isSaved();
    }

    function isPsyched() {
        return !!props.psychologistText;
    }
    function isPsychErr() {
        return isErr(props.psychologistText);
    }

    function isErr(item: undefined|string|Error): boolean {
        return !!item && typeof(item) !== 'string';
    }

    function isPsyching() {
        return props.state === State.REFLECTING;
    }

    function isPsychActionable() {
        return isEmojiActionable() && isEmojiD();
    }

    function isImagePrompted() {
        return !!props.imagePrompt;
    }

    function isImagePromptErr(): boolean {
        return isErr(props.imagePrompt);
    }

    function isImagePrompting() {
        return props.state === State.GENERATING_IMAGE_PROMPT;
    }

    function isImagePromptActionable() {
        return isPsychActionable() && isPsyched();
    }

    function isImageCreated() {
        return !!props.imageUrl;
    }

    function isImageCreateErr(): boolean {
        return isErr(props.imageUrl);
    }

    function isImageCreating() {
        return props.state === State.REFRESHING_IMAGE;
    }

    function isImageCreateActionable() {
        return isImagePromptActionable() && isImagePrompted();
    }

    function showText(txt: string | Error | undefined, refreshable:Refreshable|undefined=undefined) {
        setDisplayText(txt);
        setDisplayImage(undefined);
        setRefreshable(refreshable);
    }

    function clearDisplayText() {
        setDisplayText(undefined);
        setDisplayImage(undefined);
    }

    function proofs() {
        navigate('alternatives')
    }

    function refresh() {
        if (refreshable && props.onRefresh) {
            props.onRefresh(refreshable);
        }
    }

    async  function flagInappropriate() {
        await esApi.flagInappropriateContent(
            {
                id: newId(),
                lines: compressLines(props.lines),
                ocrText: props.ocrText,
                overriddenText: props.overrideText,
                reflectionId: newId(),
                image: props.imageUrl,
                text: props.psychologistText,
                imageAltText: props.imagePrompt,
                created: numberOfMinutesSinceDec12023(),
                lastUpdated: numberOfMinutesSinceDec12023()
            } as ScribEntry & Reflection,
            refreshable,
            inappropriateComment
        )
        sb.displayMessage(i18n('Your feedback has been submitted.  Thank you.'))
        setShowInappropriate(false)
    }



    function thumbsUp() {
        // noinspection JSIgnoredPromiseFromCall
        esApi.flagInappropriateContent(
            {
                id: newId(),
                lines: compressLines(props.lines),
                ocrText: props.ocrText,
                overriddenText: props.overrideText,
                reflectionId: newId(),
                image: props.imageUrl,
                text: props.psychologistText,
                imageAltText: props.imagePrompt,
                created: numberOfMinutesSinceDec12023(),
                lastUpdated: numberOfMinutesSinceDec12023()
            } as ScribEntry & Reflection,
            refreshable,
            "THUMBS-UP"
        )
    }


    return (<div className={styles.main + ' ' + styles.line + ' ' + TUT_5_CLASSNAME}>
        <Reflector icons={['💾', '☁️']}
                   id="saveReflector"
                   isComplete={isSaved()}
                   isError={isSaveErr()}
                   hasStarted={isSaving()}
                   isActionable={isSaveActionable()}
        />
        {props.lines && props.lines.length > 0 && (<Reflector icons={['📄', '🔍']}
                                                              id="ocrReflector"
                                                              isComplete={isOCRd()}
                                                              isError={isOCRErr()}
                                                              hasStarted={isOCRing()}
                                                              isActionable={isOCRActionable()}
                                                              onClick={() => showText(props.ocrText)}
        />)}
        <Reflector icons={['😊', '😢']}
                   id="emojiReflector"
                   isComplete={isEmojiD()}
                   isError={isEmojiErr()}
                   hasStarted={isEmojiing()}
                   isActionable={isEmojiActionable()}
                   onClick={() => {
                       showText(props.emoji)
                       setRefreshable(Refreshable.EMOJI)
                   }}
        />
            <Reflector icons={['🧠', '👩‍⚕️']}
                       id="psychologistReflector"
                       isComplete={isPsyched()}
                       hasStarted={isPsyching()}
                       isError={isPsychErr()}
                       isActionable={isPsychActionable()}
                       isPaused={isPaused}
                       onClick={() => {
                           if (!isPaused) {
                               showText(props.psychologistText)
                               setRefreshable(Refreshable.PSYCHOLOGIST_TEXT)
                           } else if (props.onResume) {
                               props.onResume();
                           }
                       }}
            />
        <Reflector icons={['🎨', '❓']}
                   id="imagePromptReflector"
                   isComplete={isImagePrompted()}
                   isError={isImagePromptErr()}
                   hasStarted={isImagePrompting()}
                   isActionable={isImagePromptActionable()}
                   onClick={() => {
                       if (!isPaused) {
                           showText(props.imagePrompt)
                           setRefreshable(Refreshable.IMAGE_PROMPT)
                       }
                   }}
        />
        <Reflector icons={['🎨', '🖌️']}
                   id="imageReflector"
                   isComplete={isImageCreated()}
                   isError={isImageCreateErr()}
                   completeIconImg={props.imageUrl}
                   hasStarted={isImageCreating()}
                   isActionable={isImageCreateActionable()}
                   onClick={() => {
                       if (!isPaused) {
                           setDisplayImage(props.imageUrl);
                           setDisplayText(props.psychologistText);
                           setRefreshable(Refreshable.IMAGE_URL);
                       }
                   }}
        />

        <Dialog className={styles.inappropriateDialog} open={showInappropriate}
                onClose={flagInappropriate} maxWidth={'md'}>
            <DialogTitle><I18N>Inappropriate Content</I18N></DialogTitle>
            <DialogContent>
                <div><I18N>Oh no! We're sorry to hear that.
                    Please let us know what's wrong with this content.</I18N></div>
                <div><I18N>We'll review it and take appropriate action.</I18N></div>
                <div>&nbsp;</div>
                <TextField id={'inappropriate-comment'}
                           label={i18n('Comment')} multiline rows={4} fullWidth name={'inappropriate-comment'}
                           value={inappropriateComment}
                           onKeyDown={createSubmitOnEnter(flagInappropriate)}
                           onChange={(e) => setInappropriateComment(e.target.value)}
                />
                <div className={styles.buttonParent}>
                    <Button
                        variant="contained" color="primary" onClick={flagInappropriate}><I18N>Submit</I18N></Button>
                </div>
                <div>&nbsp;</div>
                <div className={styles.privacyNote}><b><I18N>Privacy note:</I18N></b> <I18N>When you flag content
                    as inappropriate, we keep an anonymous record of the related journal entry to understand
                    the issue better and improve our app's safety. This process helps us identify and address
                    problems without linking the report back to you. Thank you for your contribution to keeping
                    our community secure.!</I18N>
                </div>
            </DialogContent>
        </Dialog>

        <ReflectDialog displayImage={displayImage}
                       scribEntryId={props.scribEntryId}
                       onClose={clearDisplayText}
                       onRefresh={refresh}
                       onProofs={proofs}
                       onThumbsDown={() => setShowInappropriate(true)}
                       onThumbsUp={thumbsUp}
                       displayText={displayText}
        />


    </div>)
}

export default Reflectors
