import React, {useContext, useEffect} from "react";
import {Character} from "../../../shared/types";
import CharacterForm from "./characterseditor";
import styles from './styles.module.css'
import {EsApiContext} from "../../../utils/equi-scrib-internal-api-context";
import {SimpleI18NMessage} from "../../../utils/i18n";

function CharacterSettings() {

    const [characters, setCharacters] = React.useState([] as Character[]);
    const esApi = useContext(EsApiContext);

    useEffect(() => {
        esApi.getSettings()
            .then((settings) => setCharacters(settings.characters))
            .catch((err) => console.warn(err));
    }, [esApi]);

    async function updatedCharacters(newCharacters: Character[]) {
        const currentSettings = await esApi.getSettings()
        const newSettings = {
            ...currentSettings,
            characters: newCharacters
        }
        await esApi.updateSettings(newSettings);
        setCharacters(newCharacters);
    }

    return (
        <div className={styles.drawingArea}>
            <p>
                <SimpleI18NMessage
                    msg={"Provide a short description of persons that may appear in your journal entries. These descriptions will be used to help the AI image generator render these individuals"}/>
            </p>
            <p>
                <SimpleI18NMessage
                    msg={"The first card is the most important character as it is meant to represent you as the 'author,narrator'"}/>
            </p>
            <div style={{border: '1px solid'}}><CharacterForm characters={characters}
                                                              onCharactersChanged={updatedCharacters}/></div>
        </div>
    );
}

export default CharacterSettings;
