import React, { useContext, useEffect, useState, useRef } from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Character } from '../../../../shared/types';
import { EsApiContext } from '../../../../utils/equi-scrib-internal-api-context';
import styles from './styles.module.css';

interface CharacterCardProps {
    character: Character;
    onEdit: (character: Character) => void;
    onDelete: () => void;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

export const CharacterCard: React.FC<CharacterCardProps> = ({ character, onEdit, onDelete, index, moveCard }) => {
    const esApi = useContext(EsApiContext);
    const ref = useRef<HTMLDivElement>(null);
    
    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: string | symbol | null }>({
        accept: 'CHARACTER_CARD',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientX = (clientOffset?.x || 0) - hoverBoundingRect.left;

            if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
                return;
            }

            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag, dragPreview] = useDrag<DragItem, void, { isDragging: boolean }>({
        type: 'CHARACTER_CARD',
        item: (): DragItem => {
            return { index, id: String(character.name || ''), type: 'CHARACTER_CARD' };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const dragRef = useRef<HTMLDivElement>(null);
    drag(dragRef);
    dragPreview(ref);
    drop(ref);
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('')

    useEffect(() => {
        const loadPreview = async () => {
            if (character.description) {
                setIsLoading(true);
                try {
                    const url = await esApi.doPersonaPreview(character.description);
                    setPreviewUrl(url);
                } catch (error:  any) {
                    const msg = (error.response?.data || 'An error occurred. Please try refreshing.')
                    setError(msg);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        loadPreview();
    }, [character.description, esApi]);

    return (
        <Card 
            ref={ref}
            className={`${styles.card} ${isDragging ? styles.dragging : ''}`}
            data-handler-id={handlerId}
        >
            <div 
                ref={dragRef}
                className={styles.dragHandle}
            >
                <DragIndicatorIcon />
            </div>
            <div className={styles.mediaContainer}>
                {error ? (
                    <div className={styles.errorMessage}>{error}</div>
                ) : isLoading ? (
                    <div className={styles.loadingContainer}>
                        <HourglassBottomIcon className={styles.hourGlass} />
                    </div>
                ) : (
                    <CardMedia
                        component="img"
                        image={previewUrl}
                        alt={character.description}
                        onClick={() => {onEdit(character); setError('')}}
                        className={styles.media}
                    />
                )}
                <div className={styles.actions}>
                    <IconButton 
                        aria-label="edit" 
                        onClick={() => {onEdit(character); setError('')}}
                        size="small"
                        className={styles.actionButton}
                    >
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton 
                        aria-label="delete" 
                        onClick={onDelete}
                        size="small"
                        className={styles.actionButton}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </div>
            </div>
            <CardContent>
                <Typography variant="h6" component="div" className={styles.name}>
                    {character.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" className={styles.description}>
                    {character.description}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default CharacterCard;
